import React, { useEffect, useState, useRef } from "react";
import { colours } from "../../consts.js";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FeatherIcon from "feather-icons-react";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import WaveformData from "waveform-data";
import useMouse from "@react-hook/mouse-position";
import ReactPlayer from "react-player";
import Chip from "@mui/material/Chip";
import { useLocation } from "react-router-dom";
import Utils from "../../utils";
import { Storage } from "@aws-amplify/storage";
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
function ConversationTranscript() {
  const size = useWindowSize();
  let history = useLocation().search.replace("?id=", "");
  const [conversation, setConversation] = useState({});
  const [autoscroll, setAutoscroll] = useState(false);
  const [conversationFile, setConversationFile] = useState("");
  const [trackerSet, setTrackerSet] = useState([]);
  const [topicSet, setTopicSet] = useState([]);
  const [agent, setAgent] = useState("Speaker 2");
  const [tsfilter, setTsFilter] = useState("Full");
  const [chipfilter, setChipFilter] = useState("Tracker");

  async function getConversationData() {
    // console.log({ history });
    let conversationdata = await Utils.genericGet("getConversation", history);
    let alerts =
      conversationdata.data.getConversation.campaign.company.trackers;
    alerts = JSON.parse(alerts);
    alerts = alerts.filter((z) => z.alert);
    // console.log({ conversationdata, alerts });
    conversationdata.data.getConversation.analytics = JSON.parse(
      conversationdata.data.getConversation.analytics
    );
    conversationdata.data.getConversation.questions = JSON.parse(
      conversationdata.data.getConversation.questions
    );
    conversationdata.data.getConversation.summary = JSON.parse(
      conversationdata.data.getConversation.summary
    );
    conversationdata.data.getConversation.topics = JSON.parse(
      conversationdata.data.getConversation.topics
    );
    conversationdata.data.getConversation.trackersdetected = JSON.parse(
      conversationdata.data.getConversation.trackersdetected
    );
    conversationdata.data.getConversation.transcript = JSON.parse(
      conversationdata.data.getConversation.transcript
    );
    conversationdata.data.getConversation.transcript.messages.forEach((z) => {
      z.tdiff =
        Date.parse(z.startTime) -
        Date.parse(
          conversationdata.data.getConversation.transcript.messages[0].startTime
        );
    });
    setAgent(conversationdata.data.getConversation.agenttag);
    // console.log({ conversationdata });
    let newtrackerset = [];
    conversationdata.data.getConversation.trackersdetected.forEach((z) => {
      z.matches.forEach((a) => {
        a.messageRefs.forEach((b) => {
          newtrackerset.push({
            name: z.name,
            id: b.id,
            alert: alerts.find((q) => q.name === z.name) ? true : false,
          });
        });
      });
    });
    // console.log({ newtrackerset });
    setTrackerSet(newtrackerset);
    let newtopicset = [];
    conversationdata.data.getConversation.topics.topics.forEach((z) => {
      z.messageIds.forEach((a) => {
        newtopicset.push({ name: z.text, id: a });
      });
    });
    setTopicSet(newtopicset);
    setConversation(conversationdata.data.getConversation);
    setConversationFile(conversationdata.data.getConversation.name);
  }
  React.useEffect(() => {
      getConversationData();
  }, [history]);

  // const size = useWindowSize();
  const [playicon, setPlayicon] = useState(true);
  const [waveformd, setWaveformd] = useState([]);
  const [canvswidth, setCanvswidth] = useState(1000);
  const [tagsMode, setTagsMode] = useState("trackers");
  const [progresswidth, setProgresswitdh] = useState(0);
  const inputEl = useRef(null);
  var canvref = useRef(null);
  var dref = useRef(null);
  // var mouseref = useRef(null);
  const [audioURL, setAudioURL] = useState(null);
  const mouse = useMouse(canvref, {
    enterDelay: 100,
    leaveDelay: 100,
  });
  // useEffect(() => {
  //
  // }, [size]);

  useEffect(() => {
    setCanvswidth(canvref.current.clientWidth);
  }, [canvref]);

  function redraw(cuts, sens) {
    // console.log({sens});
    if (cuts === undefined) cuts = waveformd;
    var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");
    let barwidth = parseInt(canvref.current.offsetWidth / cuts.length);
    let maxvol = Math.max(...cuts.map((z) => Math.abs(z)));
    // let minvol = Math.min(...cuts.map((z) => Math.abs(z)));
    cuts.forEach((z, zidx) => {
      let scaledy = parseInt((z / maxvol) * 75);
      // console.log(scaledy)
      if (sens[zidx].sen < -1/3) ctx.fillStyle = colours.orange100;
      else if (sens[zidx].sen > 1/3) ctx.fillStyle = colours.green100;
      else ctx.fillStyle = colours.dividergray;
      // if (z > 0) {
      //   if (zidx > 200 && zidx < 250) ctx.fillStyle = colours.dividergray;
      //   else ctx.fillStyle = colours.orange100;
      if (sens[zidx].sp === "Speaker 1") {
        // console.log("s1")
        // console.log(zidx * (barwidth + 1), 75 - Math.abs(scaledy), barwidth, Math.abs(scaledy))
        ctx.fillRect(
          zidx * (barwidth + 1),
          75 - Math.abs(scaledy),
          barwidth,
          Math.abs(scaledy)
        );
      } else {
        // console.log("s2")
        // console.log(zidx * (barwidth + 1), 75, barwidth,Math.abs(scaledy))
        ctx.fillRect(zidx * (barwidth + 1), 75, barwidth, Math.abs(scaledy));
      }
      // } else {
      //   if (zidx > 50 && zidx < 100) ctx.fillStyle = colours.dividergray;
      //   else ctx.fillStyle = colours.green100;
      // }
    });
  }

  async function getFunction() {
    var canvas = document.getElementById("canvas");
    canvas.width = canvref.current.clientWidth;

    const audioContext = new AudioContext();
    let file;
    let ftch;

    try {
        file = await Storage.get(conversationFile, { download: false });
  
         ftch = await fetch(file);
  
        if (!ftch.ok) {
          throw new Error(
            `Failed to fetch file from S3. Status: ${ftch.status}`
          );
        }
    } catch (error) {
        console.error("Error fetching file from S3:", error);
    }

    let blb = await ftch.blob();
    // console.log({ blb });

    let newnewurl = URL.createObjectURL(blb);
    setAudioURL(newnewurl);
    let arrbuff = await blb.arrayBuffer();
    async function makewavfrm(arbf) {
      const options = {
        audio_context: audioContext,
        array_buffer: arbf,
        scale: 128,
      };
      return new Promise((resolve, reject) => {
        WaveformData.createFromAudio(options, (err, waveform) => {
          if (err) {
            console.log({ err });
            reject(err);
          } else {
            // console.log({ waveform });
            resolve(waveform);
          }
          audioContext.close();
        });
      });
    }
    let wavefm = await makewavfrm(arrbuff);
    let original = wavefm.toJSON().data;
    let newsample = [];
    const cuts = original.length / 200;
    while (original.length > cuts) {
      let samp = original.splice(0, cuts);
      let sign = newsample.length % 30 < 10 ? 1 : -1;
      newsample.push(sign * Math.max(...samp));
    }
    if (original.length > 0) newsample.push(Math.max(...original));
    WaveformData.create(wavefm.toJSON());

    setWaveformd(newsample);
    if (conversation.transcript.messages) {
      let sent = conversation.transcript.messages.map((z) => {
        return {
          t: Date.parse(z.endTime) - Date.parse(z.startTime),
          sen: z.sentiment ? z.sentiment.polarity.score : 0,
          sp: z.from.name,
        };
      });
      let totaltstime = sent.reduce((a, b) => (a = a + b.t), 0);
      let tscuttime = totaltstime / 200;
      let cutter = [];
      while (sent.length > 0) {
        if (sent[0].t > tscuttime) {
          cutter.push({ t: tscuttime, sen: sent[0].sen, sp: sent[0].sp });
          sent[0].t -= tscuttime;
        } else {
          sent[1].t += sent[0].t;
          sent[1].sen = (sent[1].sen + sent[0].sen) / 2;
          sent.shift();
        }
        if (sent.length === 1) {
          cutter.push({ t: tscuttime, sen: sent[0].sen, sp: sent[0].sp });
          sent.shift();
        }
      }
      while (cutter.length < newsample.length)
        cutter.push({ t: tscuttime, sen: 0 });
      while (cutter.length > newsample.length) cutter.pop();
      redraw(newsample, cutter);
    }
  }

  useEffect(() => {
    getFunction();
  }, [conversationFile, size, conversation]);
  return (
    <div className="vocalysed-home">
      <div className="vocalysd-card card-multiple">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="card-piece">
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
              className="buttons-border-extend"
              style={{ display: "inline-block" }}
            >
              <Button
                style={{
                  background:
                    tsfilter === "Full" ? colours.primary100 : colours.white,
                  boxShadow:
                    tsfilter === "Full"
                      ? "none"
                      : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                  color: tsfilter === "Full" ? colours.white : colours.black,
                  textTransform: "none",
                  fontFamily: "Poppins",
                }}
                onClick={() => setTsFilter("Full")}
              >
                Full transcript
              </Button>
              <Button
                style={{
                  background:
                    tsfilter === "Agent" ? colours.primary100 : colours.white,
                  boxShadow:
                    tsfilter === "Agent"
                      ? "none"
                      : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                  color: tsfilter === "Agent" ? colours.white : colours.black,
                  textTransform: "none",
                  fontFamily: "Poppins",
                }}
                onClick={() => setTsFilter("Agent")}
              >
                Agent transcript
              </Button>
              <Button
                style={{
                  background:
                    tsfilter === "Client" ? colours.primary100 : colours.white,
                  boxShadow:
                    tsfilter === "Client"
                      ? "none"
                      : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                  color: tsfilter === "Client" ? colours.white : colours.black,
                  textTransform: "none",
                  fontFamily: "Poppins",
                }}
                onClick={() => setTsFilter("Client")}
              >
                Client transcript
              </Button>
            </ButtonGroup>
            <div
              style={{
                float: "right",
                display: "inline-block",
                position: "relative",
              }}
            >
              <span
                style={{
                  backgroundColor: "#21a500",
                  width: 15,
                  height: 15,
                  display: "inline-block",
                  borderRadius: "20px",
                  position: "relative",
                  marginTop: "-2px",
                  verticalAlign: "middle",
                }}
              >
                &nbsp;
              </span>{" "}
              Positive
              <span
                style={{
                  backgroundColor: colours.subduedgray,
                  width: 15,
                  height: 15,
                  display: "inline-block",
                  borderRadius: "20px",
                  position: "relative",
                  marginLeft: "10px",
                  marginTop: "-2px",
                  verticalAlign: "middle",
                }}
              >
                &nbsp;
              </span>{" "}
              Neutral
              <span
                style={{
                  backgroundColor: "#e87522",
                  width: 15,
                  height: 15,
                  display: "inline-block",
                  borderRadius: "20px",
                  position: "relative",
                  marginLeft: "10px",
                  marginTop: "-2px",
                  verticalAlign: "middle",
                }}
              >
                &nbsp;
              </span>{" "}
              Negative
            </div>

            {true ? null : (
              <TextField
                id="input-with-icon-textfield"
                label="Search"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FeatherIcon
                        icon="search"
                        style={{ color: colours.black }}
                      />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            )}
          </div>
          <div
            className="card-piece"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "5%",
                margin: "auto",
                textAlign: "end",
                marginRight: "15px",
                minWidth: "fit-content",
              }}
            >
              <ReactPlayer
                url={audioURL}
                playing={!playicon}
                ref={inputEl}
                width="0px"
                onProgress={(e) => {
                  setProgresswitdh(e.played);
                  // console.log(e.playedSeconds)
                  if (autoscroll && e.playedSeconds > 10) {
                    let ids =
                      conversation.transcript.messages
                        .map((z) => z.tdiff / 1000)
                        .filter((z) => z > Math.max(0, e.playedSeconds - 10))
                        .shift() * 1000;

                    const place = document.getElementById(ids);
                    window.scrollTo({
                      top: place.offsetTop,
                      behavior: "smooth",
                    });
                  }
                }}
                height="0px"
              />
              <div>
                {playicon ? (
                  <Button
                    onClick={() => {
                      setPlayicon(!playicon);
                    }}
                  >
                    <FeatherIcon
                      size="48"
                      icon="play-circle"
                      style={{
                        strokeWidth: 1,
                        color: colours.primary100,
                        boxShadow: "4px 4px 5px #D1D9E6, -4px -4px 4px #FFFFFF",
                        borderRadius: "108px",
                      }}
                    />
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setPlayicon(!playicon);
                    }}
                  >
                    <FeatherIcon
                      size="48"
                      icon="pause-circle"
                      style={{
                        strokeWidth: 1,
                        color: colours.primary100,
                        boxShadow: "4px 4px 5px #D1D9E6, -4px -4px 4px #FFFFFF",
                        borderRadius: "108px",
                      }}
                    />
                  </Button>
                )}
              </div>
            </div>
            <div
              style={{
                width: "95%",
                height: 170,
                borderRadius: "20px",
                boxShadow:
                  "inset 4px 4px 5px #d1d9e6, inset -4px -4px 5px #ffffff",
              }}
              ref={canvref}
              onClick={() => {
                let pos = mouse.x / mouse.elementWidth;
                setPlayicon(false);
                inputEl.current.seekTo(pos);
              }}
            >
              <canvas
                id="canvas"
                width={canvswidth}
                height={150}
                ref={dref}
                style={{ padding: "9px 0" }}
              ></canvas>
              <div
                style={{
                  position: "absolute",
                  width: canvswidth * progresswidth,
                  borderTopLeftRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  height: 170,
                  marginTop: -175,
                  background: colours.blackShaded,
                  opacity: 0.5,
                }}
              ></div>
            </div>
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: "100%",
                borderRight: "1px solid #DEE2F1",
                padding: 20,
              }}
            >
              <div
                style={
                  window.pageYOffset > 450
                    ? {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        position: "fixed",
                        background: colours.basegray,
                        width: "85%",
                        alignItems: "center",

                        top: 0,
                      }
                    : {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }
                }
              >
                <div>
                  {window.pageYOffset > 450 ? (
                    playicon ? (
                      <Button
                        onClick={() => {
                          setPlayicon(!playicon);
                        }}
                      >
                        <FeatherIcon
                          size="48"
                          icon="play-circle"
                          style={{
                            strokeWidth: 1,
                            color: colours.primary100,
                            boxShadow:
                              "4px 4px 5px #D1D9E6, -4px -4px 4px #FFFFFF",
                            borderRadius: "108px",
                          }}
                        />
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setPlayicon(!playicon);
                        }}
                      >
                        <FeatherIcon
                          size="48"
                          icon="pause-circle"
                          style={{
                            strokeWidth: 1,
                            color: colours.primary100,
                            boxShadow:
                              "4px 4px 5px #D1D9E6, -4px -4px 4px #FFFFFF",
                            borderRadius: "108px",
                          }}
                        />
                      </Button>
                    )
                  ) : null}
                  <Switch
                    value={autoscroll}
                    onChange={(c) => {
                      setAutoscroll(c.target.checked);
                    }}
                  />
                  Auto scroll
                </div>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                  className="buttons-border-extend"
                >
                  <Button
                    onClick={(c) => setTagsMode("trackers")}
                    style={{
                      background:
                        tagsMode === "trackers"
                          ? colours.primary100
                          : colours.white,
                      color:
                        tagsMode === "trackers" ? colours.white : colours.black,
                      boxShadow:
                        tagsMode === "trackers"
                          ? "none"
                          : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                      textTransform: "none",
                      fontFamily: "Poppins",
                    }}
                  >
                    Tracker
                  </Button>
                  <Button
                    onClick={(c) => setTagsMode("alerts")}
                    style={{
                      background:
                        tagsMode === "alerts"
                          ? colours.primary100
                          : colours.white,
                      color:
                        tagsMode === "alerts" ? colours.white : colours.black,
                      boxShadow:
                        tagsMode === "alerts"
                          ? "none"
                          : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                      textTransform: "none",
                      fontFamily: "Poppins",
                    }}
                  >
                    Alerts
                  </Button>
                  <Button
                    style={{
                      background:
                        tagsMode === "topics"
                          ? colours.primary100
                          : colours.white,
                      color:
                        tagsMode === "topics" ? colours.white : colours.black,
                      boxShadow:
                        tagsMode === "topics"
                          ? "none"
                          : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
                      textTransform: "none",
                      fontFamily: "Poppins",
                    }}
                    onClick={(c) => setTagsMode("topics")}
                  >
                    Topics
                  </Button>
                </ButtonGroup>
              </div>
              <div>
                {!conversation.transcript
                  ? null
                  : conversation.transcript.messages
                      // .filter((z) => {
                      //   // z.from.name === agent;
                      //   if (tsfilter === "Full") return true;
                      //   else {
                      //     if (
                      //       tsfilter === "Agent" &&
                      //       z.from.name.toUpperCase() === agent.toUpperCase()
                      //     )
                      //       return true;
                      //     else return false;
                      //   }
                      // })
                      .map((z, zidx) => (
                        <Dbox
                          key={zidx}
                          id={z.tdiff}
                          tagsMode={tagsMode}
                          filter={tsfilter}
                          input={{
                            id: z.id,
                            text: z.text,
                            phrases: JSON.stringify(z.phrases),
                            type: z.from.name === agent ? "agent" : "client",
                            comment: z.comment,
                            bm: z.bm,
                            alerts: trackerSet, //["empathy", "agent empathy"],
                            trackers: trackerSet,
                            topics: topicSet,
                          }}
                          output={async (c) => {
                            // console.log({ c });
                            Utils.sendProgressBar(true);
                            if (c.func === "settext") {
                              let tscr = Object.assign(
                                {},
                                conversation.transcript
                              );
                              let found = tscr.messages.find(
                                (a) => a.id === c.message
                              );
                              if (found) found.text = c.newtext;
                              await Utils.genericMutation(
                                "updateConversation",
                                {
                                  id: conversation.id,
                                  transcript: JSON.stringify(tscr),
                                }
                              );
                            }
                            if (c.func === "setcomment") {
                              let tscr = Object.assign(
                                {},
                                conversation.transcript
                              );
                              let found = tscr.messages.find(
                                (a) => a.id === c.message
                              );
                              if (found) found.comment = c.newtext;
                              await Utils.genericMutation(
                                "updateConversation",
                                {
                                  id: conversation.id,
                                  transcript: JSON.stringify(tscr),
                                }
                              );
                            }
                            if (c.func === "setbm") {
                              let tscr = Object.assign(
                                {},
                                conversation.transcript
                              );
                              let found = tscr.messages.find(
                                (a) => a.id === c.message
                              );
                              if (found) found.bm = c.bm;
                              let allbms = conversation.bookmarks;
                              if (allbms === null) allbms = [];
                              else allbms = allbms.split(",");
                              allbms = allbms.concat(c.bm);
                              allbms = [...new Set(allbms)];
                              allbms = allbms.join(",");

                              await Utils.genericMutation(
                                "updateConversation",
                                {
                                  id: conversation.id,
                                  transcript: JSON.stringify(tscr),
                                  bookmarks: allbms,
                                }
                              );
                              }
                            Utils.sendSnackBar(null);
                          }}
                        />
                      ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Dbox({ input, output, tagsMode, id, filter }) {
  function capcase(input) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
  const [editmode, setEditmode] = useState(false);
  const [editCommentMode, setEditCommentMode] = useState(false);
  const [text, setText] = useState("");
  const [bm, setBm] = useState([]);
  const [comment, setComment] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    if (typeof event === "string") changebm(event);
    setAnchorEl(null);
  };

  function changebm(val) {
    let newbm = Object.assign([], bm);
    if (bm.indexOf(val) > -1) newbm = bm.filter((z) => z !== val);
    else newbm.push(val);
    setBm(newbm);
    output({ func: "setbm", message: input.id, bm: newbm });
  }
  useEffect(() => {
    setText(input.text);
    setComment(input.comment);
    if (input.bm !== undefined) setBm(input.bm);
  }, []);

  function replacer(inputtxt, replacertxt) {
    replacertxt = JSON.parse(replacertxt);
    if (replacertxt.length === 0) return <div>{inputtxt}</div>;
    else {
      let newtext = (inputtxt + " ").split(replacertxt[0].text);

      let res = newtext.join(
        "<span style='background:#22c4e8;color:#FFF;padding: 0px 11px;border-radius:10px;'>" +
          replacertxt[0].text +
          "</span> "
      );
      return <div dangerouslySetInnerHTML={{ __html: res }} />;
    }
  }

  if (filter === "Full" || filter.toUpperCase() === input.type.toUpperCase())
    return (
      <div
        id={id}
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginTop: 5,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "80%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}
            >
              <div style={{ minWidth: input.type === "agent" ? 0 : 60 }} />
              <div
                style={{
                  width: 40,
                  minWidth: 40,
                  marginRight: 20,
                  height: 40,
                  background:
                    input.type === "agent" ? colours.black : colours.purple100,
                  boxShadow: "2px 2px 3px #D1D9E6, 2px 2px 3px #FFFFFF",
                  borderRadius: " 22px",
                  justifyContent: "center",
                  color: colours.white,
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {input.type === "agent" ? (
                  <FeatherIcon icon="headphones" />
                ) : (
                  <FeatherIcon icon="user" />
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    background: colours.white,
                    border: input.type === "agent" ? "1px solid #DEE2F1" : "",
                    boxSizing: "border-box",
                    boxShadow:
                      input.type === "agent"
                        ? "2px 2px 3px #D1D9E6, 2px 2px 3px #FFFFFF"
                        : "",
                    borderRadius: "0px 20px 20px 20px",
                    display: "flex",
                    flexDirection: "row",
                    minWidth: "100%",
                    padding: 10,
                    alignItems: "center",
                  }}
                >
                  {editmode ? (
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      style={{ width: "100%" }}
                      multiline={true}
                      value={text}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          output({
                            func: "settext",
                            message: input.id,
                            newtext: text,
                          });
                          setEditmode(!editmode);
                        }
                      }}
                      onChange={(c) => {
                        setText(c.target.value);
                        // console.log({ old: text, new: c.target.value });
                      }}
                    />
                  ) : (
                    <>{replacer(text, input.phrases)}</>
                  )}
                </div>
                {comment !== undefined || editCommentMode ? (
                  <div
                    style={{
                      background: colours.white,
                      border: "1px solid #DEE2F1",
                      boxSizing: "border-box",
                      boxShadow: "2px 2px 3px #D1D9E6, 2px 2px 3px #FFFFFF",
                      borderRadius: "0px 20px 20px 20px",
                      display: "flex",
                      flexDirection: "row",
                      minWidth: "100%",
                      color: colours.white,
                      padding: 10,
                      alignItems: "center",
                    }}
                  >
                    {editCommentMode ? (
                      <TextField
                        id="outlined-basic"
                        style={{ width: "100%" }}
                        multiline={true}
                        label="Comment"
                        InputLabelProps={{
                          style: { color: "#E7A500" },
                        }}
                        variant="outlined"
                        value={comment}
                        onKeyDown={(e) => {
                          if (e.keyCode == 13) {
                            output({
                              func: "setcomment",
                              message: input.id,
                              newtext: comment,
                            });
                            setEditCommentMode(!editCommentMode);
                          }
                        }}
                        onChange={(c) => setComment(c.target.value)}
                      />
                    ) : (
                      comment
                    )}
                  </div>
                ) : null}
                <div style={{ textAlign: "right", marginTop: 3 }}>
                  {bm.map((bz, bzid) => (
                    <Chip
                      label={bz}
                      key={bzid}
                      style={{
                        fontFamily: "Poppins",
                        color: colours.primary100,
                        background: "none",
                        fontSize: 13,
                        border: "1px solid #bbb",
                      }}
                      avatar={
                        <FeatherIcon
                          icon="bookmark"
                          style={{
                            fontSize: 10,
                            fontFamily: "Poppins",
                            color: colours.primary100,
                          }}
                        />
                      }
                    />
                  ))}
                </div>
              </div>
            </div>
            <div
              style={{
                width: 100,
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              <div
                style={colours.tscriptEDicon}
                className="feather-button-hover"
                onClick={() => {
                  if (editmode)
                    output({
                      func: "settext",
                      message: input.id,
                      newtext: text,
                    });
                  setEditmode(!editmode);
                }}
              >
                <FeatherIcon icon="edit" style={{ width: 20, height: 20 }} />
              </div>
              <div
                style={colours.tscriptEDicon}
                className="feather-button-hover"
                onClick={() => {
                  if (editCommentMode)
                    output({
                      func: "setcomment",
                      message: input.id,
                      newtext: comment,
                    });
                  setEditCommentMode(!editCommentMode);
                }}
              >
                <FeatherIcon
                  icon="message-square"
                  style={{ width: 20, height: 20 }}
                />
              </div>
              <div>
                <div
                  style={colours.tscriptEDicon}
                  className="feather-button-hover"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <FeatherIcon
                    icon="bookmark"
                    style={{ color: colours.primary100, width: 20, height: 20 }}
                  />
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    style={{
                      color: colours.subduedgray,
                      fontFamily: "Poppins",
                    }}
                    onClick={() => handleClose("WOW moments")}
                  >
                    WOW moments
                  </MenuItem>
                  <MenuItem
                    style={{
                      color: colours.subduedgray,
                      fontFamily: "Poppins",
                    }}
                    onClick={() => handleClose("High risk")}
                  >
                    High risk
                  </MenuItem>
                  <MenuItem
                    style={{
                      color: colours.subduedgray,
                      fontFamily: "Poppins",
                    }}
                    onClick={() => handleClose("Great interactions")}
                  >
                    Great interactions
                  </MenuItem>
                  <MenuItem
                    style={{
                      color: colours.subduedgray,
                      fontFamily: "Poppins",
                    }}
                    onClick={() => handleClose("Poor interactions")}
                  >
                    Poor interactions
                  </MenuItem>
                  <MenuItem
                    style={{
                      color: colours.subduedgray,
                      fontFamily: "Poppins",
                    }}
                    onClick={() => handleClose("Other")}
                  >
                    Other
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "20%", padding: 0 }}>
          {tagsMode === "trackers"
            ? input.trackers
                .filter((z) => z.id === input.id)
                .map((z, zidx) => (
                  <Chip
                    key={zidx}
                    label={capcase(z.name)}
                    style={{ padding: 0, fontFamily: "Poppins" }}
                  />
                ))
            : null}
          {tagsMode === "alerts"
            ? input.trackers
                .filter((z) => z.alert)
                .filter((z) => z.id === input.id)
                .map((z, zidx) => (
                  <Chip
                    key={zidx}
                    label={capcase(z.name)}
                    style={{ padding: 0, fontFamily: "Poppins" }}
                  />
                ))
            : null}
          {tagsMode === "topics"
            ? input.topics
                .filter((z) => z.id === input.id)
                .map((z, zidx) => (
                  <Chip
                    key={zidx}
                    label={capcase(z.name)}
                    style={{ fontFamily: "Poppins" }}
                  />
                ))
            : null}
        </div>
      </div>
    );
  else return null;
}

export default ConversationTranscript;
