import React, { useEffect, useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Hub, Auth } from 'aws-amplify';
import AppBar from "@mui/material/AppBar";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import ProjectVersionInput from "../package.json";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "./components/drawer-bar/drawer";
import CustomHeader from "./components/header/header";
import AppRoutes from "./routes/routes";
import { AuthProvider, AuthContext } from "./components/auth/AuthContext";
import {
    BrowserRouter as Router,
    useLocation,
} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { colours } from "./consts";
import Utils from "./utils";
import IconButton from "@mui/material/IconButton";
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));

function TT({ info }) {
    return (
        <HtmlTooltip
            title={
                <React.Fragment>
                    <Typography color="inherit" style={{ fontFamily: "Poppins" }}>
                        {info}
                    </Typography>
                </React.Fragment>
            }
        >
            <IconButton style={{ marginLeft: 30, marginTop: "-8px" }}>
                <FeatherIcon icon="info" style={{ color: "#e7a500", width: 20, height: 20 }} />
            </IconButton>
        </HtmlTooltip>
    );
}


const ProjectVersion = ProjectVersionInput.version;

const sizeOfMenuDrawer = 100;

function DrawerBar({ admincheck }) {
    const [pagename, setPagename] = useState("Analytics");
    let history = useLocation();
    useEffect(() => {
        const pages = [
            { path: "/trackers", name: "Trackers" },
            { path: "/alerts", name: "Alerts" },
            { path: "/tracker", name: "Tracker" },
            { path: "/", name: "Analytics" },
            { path: "/admin", name: "Admin" },
            { path: "/campaigns", name: "Campaigns" },
            { path: "/trackeradmin", name: "Tracker admin" },
            { path: "/campaign", name: "Campaigns" },
            { path: "/adminusers", name: "Admin users" },
            { path: "/organisation", name: "Organisation" },
            { path: "/conversation", name: "Conversation" },
            { path: "/playlists", name: "Playlists" },
            { path: "/profile", name: "Profile" },
            { path: "/trackers", name: "Trackers" },
            { path: "/trackers", name: "Trackers" },
            { path: "/organisationsetup", name: "Organisation setup" },
            { path: "/privacy-policy", name: "Privacy Policy" },
        ];
        if(pages.find((z) => z.path === history.pathname))
            window.scrollTo(0, 0);
        setPagename(pages.find((z) => z.path === history.pathname).name);
    }, [history.pathname]);

    return (
        <CustomDrawer
                sizeOfMenuDrawer={sizeOfMenuDrawer}
                pagename={pagename}
                setPagename={setPagename}
                colours={colours}
                admincheck={admincheck}
                Auth={Auth}
                ProjectVersion={ProjectVersion}
            />
    );
}

function Header({ setIsadmin }) {
    let history = useLocation();
    let navigate = useNavigate();
    const {currentNonAdminGroup, isAuthenticated, isAdmin, userGroups } = React.useContext(AuthContext);
    const [user, setUser] = useState(null);
    const [username, setUsername] = useState();
    const [anchorEl, setAnchorEl] = React.useState();
    const [anchorEl2, setAnchorEl2] = React.useState();
    const [coname, setConame] = React.useState();
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const pages = [
        { path: "/trackers", name: "Trackers" },
        { path: "/alerts", name: "Alerts" },
        { path: "/tracker", name: "Tracker" },
        { path: "/", name: "Analytics" },
        { path: "/admin", name: "Admin" },
        { path: "/campaigns", name: "Campaigns" },
        { path: "/trackeradmin", name: "Tracker admin" },
        { path: "/campaign", name: "Campaigns" },
        { path: "/adminusers", name: "Admin users" },
        { path: "/organisation", name: "Organisation" },
        { path: "/conversation", name: "Conversation" },
        { path: "/playlists", name: "Playlists" },
        { path: "/profile", name: "Profile" },
        { path: "/trackers", name: "Trackers" },
        { path: "/trackers", name: "Trackers" },
        { path: "/organisationsetup", name: "Organisation setup" },
        { path: "/privacy-policy", name: "Privacy Policy" },
    ]; // Define your pages array

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };
    const [companyDT, setCompanyDT] = React.useState([]);
    


    useEffect(() => {
        async function getCompanyData() {
            try {
                if (!isAuthenticated) return;

            // Check if user groups include "admin"
            if (isAdmin) {
                setIsadmin(true);
            } else {
                return; // Exit if not an admin
            }
        
                let company = await Utils.genericList("listCompanies", {
                    filter: { internalname: { eq: currentNonAdminGroup } },
                });
                let companydata = company.data.listCompanies.items[0].id;
                company = companydata;
                company = await Utils.genericGetMOD("getCompany", company, {
                    before: "alerts",
                    after: "alerts(limit:10000)",
                });
                // console.log({ company });
                setConame(company.data.getCompany.name);
    
                let usrnm = (
                    await Utils.genericGet("getUser", (await Utils.getUserName()).username)
                ).data.getUser;
                if(usrnm && usrnm.firstname && usrnm.lastname)
                    setUsername(usrnm.firstname + " " + usrnm.lastname);
                company = company.data.getCompany.alerts.items;
                company.forEach((z) => (z.createdAt = new Date(z.createdAt)));
                // console.log({ user, company, usrnm });
                setCompanyDT(company);
            } catch(e) {
                console.log(e);
            }
        }
        Hub.listen("auth", ({ payload: { event, data } }) => {
            //      console.log(event);
            switch(event) {
                case "signIn":
                case "cognitoHostedUI":
                    getUser().then((userData) => setUser(userData));
                    break;
                case "signOut":
                    setUser(null);
                    break;
                case "signIn_failure":
                case "cognitoHostedUI_failure":
                    console.log("Sign in failure", data);
                    break;
                default:
                    break;
            }
        });
        getCompanyData();
        getUser()
            .then((userData) => setUser(userData))
            .then((z) => Utils.getUserGroupFromCognito())
            .then((z) => Utils.setUserGroup(z))
            .catch((e) => console.log(e));
    }, [currentNonAdminGroup, isAdmin, isAuthenticated]);
    // console.log({ user });

    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then((userData) => {
                Utils.setUser(userData.username);
                return userData;
            })

            .catch(() => Auth.federatedSignIn());/*console.log("Not signed in"));*/
    }

    return (
        
        <AppBar className={history.pathname === '/organisation'
            ? coname
            : pages.find((z) => z.path === history.pathname).name 
        }
            position="static"
            style={{
                background: "transparent",
                height: 80,
                boxShadow: "none",
                // borderBottom: "2px solid #DEE2F1",
            }}
        >
            <CustomHeader
                history={history}
                pages={pages}
                user={user}
                companyDT={companyDT}
                open={open}
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
                open2={open2}
                anchorEl2={anchorEl2}
                handleClick2={handleClick2}
                handleClose2={handleClose2}
                navigate={navigate}
                colours={colours}
                username={username}
            />
        </AppBar>
    );
}

function App() {
    const [snackText, setSnackText] = React.useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [isadmin, setIsadmin] = React.useState(false);
    const [isuser, setUser] = React.useState(false);
    

    useEffect(() => {
        Hub.listen("snackbar", ({ payload: { message } }) => {
            if(message === null) setSnackOpen(false);
            else {
                setSnackText(message);
                setSnackOpen(true);
            }
        });
    }, []);
    useEffect(() => {
        Utils.getUserName().then((z) => setUser(true));
    }, []);

    return (
        <AuthProvider>
        <Router>
            <DrawerBar admincheck={isadmin} />
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={() => setSnackOpen(false)}
                message={snackText}
                className="snack-bar"
            />
            <div style={{ marginLeft: sizeOfMenuDrawer }}>
                <Header setIsadmin={(d) => setIsadmin(d)} />
                <AppRoutes isUser={isuser} /> {/* Use AppRoutes */}
            </div>
        </Router>
        </AuthProvider>
    );
}

export default App;
