import { Storage, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import * as queries from "./graphql/queries";
import { Hub } from "@aws-amplify/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "./graphql/mutations";

let user = "";
let userGroup = "";

const Utils = {
  setUser(inp) {
    user = inp;
  },
  getUser() {
    return user;
  },
  setUserGroup(inp) {
    userGroup = inp;
  },
  getUserGroup() {
    return userGroup;
  },
  async GenAdmFunc(func, params) {
    let user = (await Auth.currentAuthenticatedUser()).username;
    // console.log({func, user, params})
    let s = await fetch(
      "https://8aia1juk87.execute-api.eu-west-1.amazonaws.com/production/auth/inviteuser",
      {
        method: "post",
        body: JSON.stringify({ func, user, params }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    let res = await s.json();
    return res;
  },
  sendSnackBar(message) {
    Hub.dispatch("snackbar", { message });
  },
  sendProgressBar(isLoading) {
    if (isLoading) {
      // Show the progress bar
      Hub.dispatch("progressbar", { isLoading: true, progress: 0 });

      // Simulate data load and progress update
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        Hub.dispatch("progressbar", { isLoading: true, progress });

        if (progress >= 100) {
          clearInterval(interval);
          Hub.dispatch("progressbar", { isLoading: false, progress: 100 }); // Hide the progress bar
        }
      }, 100); 
    }
  },
  convertURLtoObject(url) {
    url = url.replace("?", "");
    url = url.split("&");
    let params = {};
    url.forEach((z) => {
      let item = z.split("=");
      params[item[0]] = item[1];
    });
    return params;
  },
  async savetos3(file, authContext) {
    if (!authContext.isAuthenticated) {
        throw new Error("User is not authenticated. Cannot upload to S3.");
    }

    // Extract user group and ensure non-admin folder
    //const folder =
    //    authContext.user.signInUserSession.idToken.payload["cognito:groups"]?.filter(
    //        (z) => z !== "admin"
    //    )[0] || "default";

    // Refresh authContext to ensure updated group info
    const updatedGroups = await Utils.refreshUserGroups();
    authContext.user.signInUserSession.idToken.payload["cognito:groups"] = updatedGroups;
  
    // Extract user group and ensure non-admin folder
    const folder =
      updatedGroups?.filter((z) => z !== "admin")[0] || "default";
    
    // Generate UUID for file naming
    const create_UUID = () => {
        let dt = new Date().getTime();
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            let r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        });
    };

    // Extract file extension
    const ext = file.name.split(".").pop() || "unknown";

    // Prepare metadata
    const rawMetadata = {
        uploadedBy: String(folder),
        originalFileName: String(file.name),
        size: String(file.size),
        uploadDate: new Date().toISOString(),
    };

    // Ensure all metadata values are strings
    const metadata = Object.keys(rawMetadata).reduce((acc, key) => {
        acc[key] = rawMetadata[key];
        return acc;
    }, {});

    // Perform the upload
    try {
        const fileName = `${folder}/${create_UUID()}.${ext}`;
        const result = await Storage.put(fileName, file, {
            contentType: file.type || "application/octet-stream"
        });



        // Return both the result and the file name for further use
        return { ...result, fileName };
    } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
    }
  }


,
  async lists3(folder) {
    return await Storage.list(folder + "/");
  },
  async getUserGroupFromCognito() {
    let s = await Auth.currentAuthenticatedUser();
    let t = await Utils.GenAdmFunc("adminListGroupsForUser", {
      Username: s.username,
      UserPoolId: "STRING_VALUE",
    });
    return t;
  },

  async getUserName() {
    return await Auth.currentAuthenticatedUser();
  },

  async refreshUserGroups() {
    let refresh = new Promise((res, rej) => {
      Auth.currentAuthenticatedUser().then((user) => {
        Auth.currentSession().then((session) => {
          user.refreshSession(session.refreshToken, (err, sessn) => {
            // console.log(sessn.idToken.payload["cognito:groups"]);
            if (err) rej(err);
            else res(session.idToken.payload["cognito:groups"]);
          });
        });
      });
    });

    await refresh;
    let r2 = new Promise((res, rej) => {
      setTimeout(() => {
        Auth.currentAuthenticatedUser().then((z) =>
          res(z.signInUserSession.idToken.payload["cognito:groups"])
        );
      }, 1000);
    });
    return await r2;
  },

  async genericMutation(table, input) {
    return await API.graphql(graphqlOperation(mutations[table], { input }));
  },
  create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  },

  async getpicfroms3(file) {
    try {
      let s = await Storage.get(file);
      return s;
    } catch (err) {
      console.log(err);
      return null;
    }
  },
  async genericGet(table, id) {
    return await API.graphql(
      graphqlOperation(queries[table], {
        id,
      })
    );
  },
  async genericGetMOD(table, id, MOD) {
    return await API.graphql(
      graphqlOperation(queries[table].replace(MOD.before, MOD.after), {
        id,
      })
    );
  },
  async genericList(table, filter) {
    try {
      return await API.graphql(graphqlOperation(queries[table], filter));
    } catch (err) {
      console.log(err);
    }
  },
  async genericListMOD(table, filter, MOD) {
    const listConversations = /* GraphQL */ `
      query ListConversations(
        $filter: ModelConversationFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listConversations(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            name
            agent
            agenttag
            startdate
            enddate
            duration
            VS
            symblstatus
            symbljobid
            symblconversationid
            alertcount
            alerthits
            deepgramSummary
            file
            groups
            createdAt
            updatedAt
            campaignConversationsId
          }
          nextToken
        }
      }
    `;
    try {
      return await API.graphql(
        graphqlOperation(
          MOD
            ? listConversations
            : queries[table].replace(MOD.before, MOD.after),
          filter
        )
      );
    } catch (err) {
      console.log(err);
    }
  },
  async genericListMOD2(table, filter, MOD) {
    const listConversations = /* GraphQL */ `
      query ListConversations(
        $filter: ModelConversationFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listConversations(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            bookmarks
          }
          nextToken
        }
      }
    `;
    try {
      return await API.graphql(
        graphqlOperation(
          MOD
            ? listConversations
            : queries[table].replace(MOD.before, MOD.after),
          filter
        )
      );
    } catch (err) {
      console.log(err);
    }
  },
  async genericListMOD3(nextToken) {
    const listConversations = /* GraphQL */ `
      query ListConversations(
        $filter: ModelConversationFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listConversations(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            agent
            VS
          }
          nextToken
        }
      }
    `;
    try {
      return await API.graphql(
        graphqlOperation(listConversations, { nextToken })
      );
    } catch (err) {
      console.log(err);
    }
  },
  async genericListMOD4() {
    const listConversations = /* GraphQL */ `
      query ListConversations(
        $filter: ModelConversationFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listConversations(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            agent
            VS
          }
          nextToken
        }
      }
    `;
    try {
      return await API.graphql(graphqlOperation(listConversations));
    } catch (err) {
      console.log(err);
    }
  },
  async genericListMOD5(filter) {
    const listConversations = /* GraphQL */ `
      query ListConversations(
        $filter: ModelConversationFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listConversations(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
          }
          nextToken
        }
      }
    `;
    try {
      return await API.graphql(graphqlOperation(listConversations, filter));
    } catch (err) {
      console.log(err);
    }
  },
};
export default Utils;
