function Privacy() {
  window.location.href = "https://vault.vocalysd.ai/privacy-policy/";
  return (
    <div className="privacy-policy" style={{ margin: "20px", fontFamily: "Poppins, sans-serif" }}>
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> 28 January 2025 </p>
      <p><strong>Last Updated:</strong> 28 January 2025</p>

      <h2>1. Introduction</h2>
      <p>
        Vocalysd.ai ("we," "us," or "our") is committed to protecting your privacy and ensuring your personal information is handled in compliance with applicable data protection laws, including the <strong>General Data Protection Regulation (GDPR)</strong>, <strong>California Consumer Privacy Act (CCPA)</strong>, and <strong>Protection of Personal Information Act (POPIA)</strong>. This Privacy Policy explains how we collect, use, store, and protect your personal information when you use our website, applications, and services (collectively, the "Services").
      </p>
      <p>
        By using our Services, you agree to the terms of this Privacy Policy. If you do not agree, please refrain from using our Services.
      </p>

      <h2>2. Information We Collect</h2>
      <h3>2.1 Non-Personal Information</h3>
      <p>We may collect non-identifiable information, including:</p>
      <ul>
        <li>IP Address</li>
        <li>Browser Type and Version</li>
        <li>Device Information</li>
        <li>Operating System</li>
      </ul>

      <h3>2.2 Personal Information</h3>
      <p>
        When you register or use our Services, we may collect the following personal information:
      </p>
      <ul>
        <li>Your name, email address, and company name.</li>
        <li>Information provided when completing forms on our platform.</li>
        <li>Records of communications with us (e.g., support queries).</li>
        <li>Details of transactions carried out through our platform.</li>
        <li>Information from surveys completed for research purposes.</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>We use the information collected to:</p>
      <ul>
        <li>Provide, manage, and improve our Services.</li>
        <li>Process transactions and provide customer support.</li>
        <li>Personalize your experience with our Services.</li>
        <li>Send updates, notifications, or marketing materials (with your consent).</li>
        <li>Analyze usage patterns to enhance functionality.</li>
        <li>Comply with legal obligations.</li>
      </ul>

      <h2>4. Cookies and Tracking</h2>
      <p>We use <strong>Cookies</strong> and similar technologies to:</p>
      <ul>
        <li>Improve user experience.</li>
        <li>Analyze traffic and usage.</li>
        <li>Store your preferences.</li>
      </ul>
      <p>
        You may control or disable Cookies via your browser settings. However, this may affect the functionality of our Services.
      </p>

      <h2>5. Sharing of Information</h2>
      <p>We do not sell your personal information. However, we may share it in the following cases:</p>
      <ul>
        <li>With service providers who process data on our behalf (e.g., hosting and analytics).</li>
        <li>To comply with legal requirements (e.g., subpoenas, court orders).</li>
        <li>In connection with a merger, acquisition, or sale of assets.</li>
      </ul>

      <h2>6. Data Security</h2>
      <p>
        We take appropriate technical and organizational measures to safeguard your information, including:
      </p>
      <ul>
        <li>Encrypted communication (e.g., SSL/TLS).</li>
        <li>Access controls and authentication mechanisms.</li>
        <li>Regular security audits.</li>
      </ul>
      <p>
        However, no system is completely secure. You acknowledge and accept the risks associated with transmitting data online.
      </p>

      <h2>7. Data Retention</h2>
      <p>
        We retain personal information only as long as necessary for the purposes described in this Privacy Policy or as required by law. You can request deletion of your personal data at any time.
      </p>

      <h2>8. Your Rights</h2>
      <p>Depending on your location, you may have the following rights:</p>
      <ul>
        <li><strong>Access:</strong> Request a copy of your personal data.</li>
        <li><strong>Rectification:</strong> Correct inaccurate or incomplete data.</li>
        <li><strong>Erasure:</strong> Request deletion of your data ("right to be forgotten").</li>
        <li><strong>Data Portability:</strong> Receive your data in a structured format.</li>
        <li><strong>Withdraw Consent:</strong> Revoke consent for data processing at any time.</li>
        <li><strong>Object:</strong> Restrict or object to specific types of data processing.</li>
      </ul>
      <p>To exercise your rights, contact us at <a href="mailto:monique.hoffman@matellio.com">privacy@vocalysd.ai</a>.</p>

      <h2>9. Third-Party Links</h2>
      <p>
        Our Services may contain links to third-party websites. We are not responsible for their content or privacy practices. Please review their privacy policies before providing any personal information.
      </p>

      <h2>10. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Changes will be posted on this page with the "Last Updated" date. Significant updates will be communicated via email or within the platform. We encourage you to review this policy regularly.
      </p>

      <h2>11. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us:
      </p>
      <p><strong>Email:</strong> <a href="monique.hoffman@matellio.com">privacy@vocalysd.ai</a></p>
      <p><strong>Address:</strong> 134 River Road, Kyalami Heights, Midrand, South Africa</p>
    </div>
  );
}

export default Privacy;

